import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import ApiAdmin from "../../../api-admin";
import Spinner from "../Spinner";
import { useTranslate } from "../TranslationProvider";
import classNames from "classnames";

const SubscriptionPeriods = ({ userId }) => {
  const [fetchData, setFetchData] = useState({
    userSubPeriods: null,
    isFetching: false,
    error: null,
  });
  const translate = useTranslate();

  const fetchSubPeriods = () => {
    setFetchData({ userSubPeriods: null, isFetching: true, error: null });

    ApiAdmin.getUserSubscriptionPeriods(userId).then((response) => {
      if (response.error) {
        setFetchData({
          userSubPeriods: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        setFetchData({
          userSubPeriods: response.subscriptionPeriods,
          isFetching: false,
          error: null,
        });
      }
    });
  };

  useEffect(() => {
    fetchSubPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const renderSpinner = () => {
    return (
      <div className="blue-box">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    return <Alert>{fetchData.error}</Alert>;
  };

  const renderEmptyPeriods = () => {
    return (
      <div className="blue-box">
        {translate("subscription_period")} {translate("not_available")}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="blue-box">
        {translate("subscription_period")}:{" "}
        {fetchData.userSubPeriods?.map((period) => {
          return (
            <div
              key={period.id}
              className={classNames("list-item-opaque", {
                "sub-period-expired": period.expired,
              })}
            >
              <p className="no-margin">
                {period.localStartDate}, {translate("valid_until")}{" "}
                {period.localEndDate}, {period.userSchoolId}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {fetchData.isFetching && renderSpinner()}
      {fetchData.error && renderError()}
      {fetchData.userSubPeriods?.length === 0
        ? renderEmptyPeriods()
        : renderContent()}
    </div>
  );
};

export default SubscriptionPeriods;
