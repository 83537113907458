import React, {Component} from 'react';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';
import Lightbox from 'react-image-lightbox';
import Collapse from 'react-bootstrap/Collapse';
import TrackerAB from '../../../utils/trackerAB';

const B_PROMO1 = require('../../../assets/webshop/b_book_menu.jpg');
const B_PROMO2 = require('../../../assets/webshop/b_book_signs.jpg');
const B_PROMO3 = require('../../../assets/webshop/b_book_bicycle.jpg');
const B_PROMO4 = require('../../../assets/webshop/b_book_tram_lane.jpg');
const B_PROMO5 = require('../../../assets/webshop/b_book_phone_sample.png');

const M_PROMO1 = require('../../../assets/webshop/m_book_menu.jpg');
const M_PROMO2 = require('../../../assets/webshop/m_book_license.jpg');
const M_PROMO3 = require('../../../assets/webshop/m_book_handling.jpg');
const M_PROMO4 = require('../../../assets/webshop/m_book_bus.jpg');

const PROMO_IMAGES = {
  'b_book': [B_PROMO1, B_PROMO2, B_PROMO3, B_PROMO4, B_PROMO5],
  'm_book': [M_PROMO1, M_PROMO2, M_PROMO3, M_PROMO4]
};

class BookPromoComponent extends Component {

  constructor(props, context) {
    super(props, context);
    this.openModal = this.openModal.bind(this);
    this.openInfo = this.openInfo.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      showInfo: true,
      showModal: false,
      photoIndex: 0,
    }
  }

  renderImages(bookId) {
    const imageElems = PROMO_IMAGES[bookId].map(fileName => {
      return (<img src={fileName} className="top-margin book-promo-image" key={fileName}/>);
    });
    return (
      <div className="centered">
        {imageElems}
      </div>);
  }

  openModal() {
    TrackerAB.track('webshop-bookpromo-images-opened');
    this.setState({showModal: true});
  }

  openInfo() {
    TrackerAB.track('webshop-bookpromo-info-opened');
    this.setState({showInfo: true});
  }

  closeModal() {
    TrackerAB.track('webshop-bookpromo-images-closed');
    this.setState({showModal: false});
  }

  renderLightbox() {
    const {photoIndex, showModal} = this.state;
    if (!showModal) {
      return null;
    }

    const bookId = this.props.config['bookId'];
    const images = PROMO_IMAGES[bookId];

    return (
      <Lightbox
        enableZoom={false}
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={this.closeModal}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  }

  render() {
    const bookId = this.props.config['bookId'];
    return (
      <div className="blue-inner-box tight">
        <div className="vertical-middle on-sides">
          <Translated translationKey="contains_book" className="strong yellow"/>
          {this.state.showInfo ? null : <Button onClick={this.openInfo}><Translated translationKey="read_more"/></Button>}
        </div>
        <Collapse in={this.state.showInfo}>
          <div>
            <div><Translated translationKey={'book_promo_'+bookId}/></div>
            <Button className="top-margin" onClick={this.openModal}><Translated translationKey="show_images"/></Button>
          </div>
        </Collapse>
        {this.renderLightbox()}
      </div>
    )
  }

}

export default BookPromoComponent;

