import React, {Component} from 'react';
import Translated from './Translated';
import {IoAlertOutline, IoPrintOutline, IoLockClosedSharp, IoLockOpenSharp, IoCalendarClearSharp, IoReader, IoLogoFacebook, IoMail} from 'react-icons/io5';
import {BsFillShieldLockFill} from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Spinner from './Spinner';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import DateUtil from '../../utils/date';
import Tracker from '../../utils/tracker';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import withTranslate from './withTranslate';
import UserAddEmail from './UserAddEmail';
import Alert from 'react-bootstrap/Alert';
import api from '../../api';
import {Roles, fillsRole} from "../../data/roles";
import SubscriptionPeriods from './admin/SubscriptionPeriods';
import { RiEdit2Fill, RiCheckFill, RiCloseFill } from "react-icons/ri";
import { Card, FormGroup } from 'react-bootstrap';


class EditUser extends Component {

  constructor(props, context) {
    super(props, context);
    this.showPrintableCodes = this.showPrintableCodes.bind(this);
    this.disconnectEmail = this.disconnectEmail.bind(this);
    this.removeEmailPassword = this.removeEmailPassword.bind(this);
    this.sendResetPasswordLink = this.sendResetPasswordLink.bind(this);
    this.state = {
      showPrintableCodes: false,
      name: '',
      isEditingUserName: false,
      userName: this.props.profile.userData.name,
      customDayPeriod: 15,
      isEditingEmail: false,
      email: this.props.profile.userData.email || "",
      isModalPasswordRecovery:false,
      isLoadingPasswordRecovery:false,
      passwordRecoveryError:null,
      passwordRecoveryLinkSent:false,
    };
  }

  isProfileClosed(profile) {
    return (profile.userData.isClosed || profile.userData.isClosedByDate);
  }

  isProfileActivated(profile) {
    return profile.userData.activationDate !== null;
  }

  validateEmail(email) {
    return /.@.+\../.test(email);
  }

  saveUserData(overridingData, callback) {
    Tracker.logEvent('user', 'edit');
    this.props.saveUserMethod(Object.assign({}, this.props.profile.userData, overridingData), callback);
  }

  saveClosingInDays(days) {
    this.saveUserData({'setOpenForDays': days});
  }

  saveChangedUserName(name) {
    this.saveUserData({'name' : name}, this.setState({isEditingUserName: false}))
  }

  disconnectEmail() {
    this.props.disconnectEmail();
  }
  
  removeEmailPassword() {
    this.props.removeEmailPassword();
  }

  sendResetPasswordLink(e) {
    e.preventDefault();
    const userData = this.props.profile.userData;
    api
      .userEmailRequestEmailRecovery(userData.email, userData.schoolLanguage)
      .then((response) => {
        if (response.error) {
          return this.setState({
            passwordRecoveryError: response.error,
            isLoadingPasswordRecovery: false,
          });
        }
        this.setState({
          isLoadingPasswordRecovery: false,
          passwordRecoveryLinkSent: true,
        });
      });
  }

  renderResetPasswordModal() {
    return (
      <Modal
        show={this.state.isModalPasswordRecovery}
        onHide={() => this.setState({ isModalPasswordRecovery: false })}
      >
        <Modal.Body>
          <Card id="recover-email-password-panel" className="login-form-well">
            <Card.Body>
              <h3>
                <Translated translationKey="recover_email_password_info" />
              </h3>
              <form id="email-login-form" onSubmit={this.sendResetPasswordLink}>
                <FormGroup>
                  <FormControl
                    id="email-to-recover"
                    type="text"
                    name="email"
                    value={this.props.profile.userData.email}
                    readOnly
                  />

                  <div className="field-spacer" />
                  
                </FormGroup>
                <Button
                  id="email-request-create-password-submit"
                  type="submit"
                  disabled={
                    this.state.isFetching || this.state.passwordRecoveryLinkSent
                  }
                  className="submit-login-button strong-text"
                >
                  {this.state.isFetching ? <Spinner /> : "recover"}
                </Button>
              </form>
              {this.state.passwordRecoveryLinkSent && (
                <Alert>
                  <Translated
                    translationKey="recovery_is_sent"
                  />
                </Alert>
              )}
              {this.state.passwordRecoveryError && (
                    <Alert className="p-0 my-1">
                      <Translated
                        translationKey={this.state.passwordRecoveryError}
                      />
                    </Alert>
                  )}
            </Card.Body>
          </Card>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => this.setState({ isModalPasswordRecovery: false })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPrintableCodes(profile) {

    if (fillsRole(profile.userData.role, Roles.TEACHER)) {
      return null;
    }

    const codeElems = [];
    profile.oneTimeKeys.forEach((code) => {
      if (code.activationDate && !code.isOpen) {
        codeElems.push(
          <div key={code.keyStr}><s>{code.keyStr.toUpperCase()}</s></div>
        )
      } else {
        codeElems.push(
          <div key={code.keyStr}>{code.keyStr.toUpperCase()}</div>
        )
      }
    });

    return (
      <Modal show={this.state.showPrintableCodes} onHide={() => this.setState({showPrintableCodes: false})}>
        <Modal.Header closeButton>
          <Modal.Title><Translated translationKey="print"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="printable no-margin">
            <div>www.ajokaista.com</div>
            <h3>{profile.userData.name}</h3>
            <div><i>(#{profile.userData.id})</i></div>
            <h4>{profile.userData.schoolName}</h4>
            <div className="uppercase">
              <strong><Translated translationKey="school_code"/>:</strong> {profile.userData.schoolId}
            </div>
            <div className="uppercase"><strong><Translated translationKey="login_code"/>:</strong></div>
            <br />
            {codeElems}
          </div>
          <br />
          <Alert>
            <IoAlertOutline />&nbsp;
            <Translated translationKey="suggest_email"/>
          </Alert>

        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => window.print()}>
            <IoPrintOutline/> <Translated translationKey="print"/>
          </Button>
          <div className="top-margin-small">
            {this.renderEmailForm()}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  renderEmailForm() {
    const isValidEmail = this.validateEmail(this.state.email);
    let emailFormContent;
    const emailStatus = this.state.emailStatus;
    if (!emailStatus) {
      emailFormContent = (
        <Form inline="true">
          <FormControl type="text" value={this.state.email} placeholder={this.props.translate('email')}
                       onChange={e => this.setState({email: e.target.value})}/>
          <Button onClick={() => this.sendByEmail()} disabled={!isValidEmail}>
            <Translated translationKey="send"/>
          </Button>
        </Form>
      )
    } else if (emailStatus.isSending) {
      emailFormContent = <Spinner/>
    } else if (emailStatus.error) {
      emailFormContent = <Translated translationKey={emailStatus.error}/>
    } else {
      emailFormContent = <Translated translationKey="message_sent"/>
    }
    return (
      <div className="edit-user-email-form">
        {emailFormContent}
      </div>
    );
  }

  sendByEmail() {
    this.setState({emailStatus: {isSending: true}});
    api.emailOneTimeKeys(this.props.profile.userData.id, this.state.email)
      .then(response => {
        this.setState({emailStatus: response});
      });
  }

  saveUserName() {
    this.setState({showNamingModal: false});
    this.saveUserData({name: this.state.name}, this.showPrintableCodes)
  }

  renderNamingModal() {
    return (
      <Modal show={this.state.showNamingModal}>
        <Modal.Body>
          <h4><Translated translationKey="must_assign_name"/></h4>
        </Modal.Body>
        <Modal.Footer>
          <Button bsstyle="primary" onClick={() => this.setState({showNamingModal: false})}>
            <Translated translationKey="close"/>
          </Button>
          <Form inline onSubmit={(e) => e.preventDefault()} className="edit-user-input-form">
            <FormControl type="text" value={this.state.name} placeholder={this.props.translate('login_student_name')}
                         onChange={e => this.setState({name: e.target.value})}/>
            <Button onClick={() => this.saveUserName()} disabled={!this.state.name}>
              <Translated translationKey="save"/>
            </Button>
          </Form> </Modal.Footer>
      </Modal>
    )
  }

  renderImage(facebookUser) {
    if (facebookUser) {
      return (<div><img className="user-profile-image fade-in" src={facebookUser.pictureUrl}/></div>);
    } else {
      return null;
    }
  }

  renderNameElem(profile) {
    const nameElem = (
      <div className="edit-user-name">
        {profile.userData.name}{" "}
        {this.props.isAdminView && <RiEdit2Fill
          className="touchable"
          onClick={() => this.setState({ isEditingUserName: true })}
        />}
        {this.props.isSaving ? <Spinner className="primary" /> : null}
      </div>
    );
    
    const editingNameElem = (
      <div className="edit-user-name">
        <div className="edit-username">
          <Form.Control
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.setState({ isEditingUserName: false });
                this.saveChangedUserName(this.state.userName);
              }
              if (e.key === "Escape") {
                this.setState({
                  isEditingUserName: false,
                  userName: this.props.profile.userData.name,
                });
              }
            }}
            value={this.state.userName}
            onChange={(e) => this.setState({ userName: e.target.value })}
            type="userName"
          />
          <RiCheckFill
            fontSize={32}
            className="touchable"
            onClick={() => this.saveChangedUserName(this.state.userName)}
          />
          <RiCloseFill
            fontSize={32}
            className="touchable"
            onClick={() =>
              this.setState({
                isEditingUserName: false,
                userName: this.props.profile.userData.name,
              })
            }
          />
        </div>
        {this.props.isSaving ? <Spinner className="primary" /> : null}
      </div>
    );
    const userIdElem = <span><i>(ID #{profile.userData.id})</i></span>;
    return (
      <div>
        {this.state.isEditingUserName ? editingNameElem  : nameElem}
        {userIdElem}
      </div>
    );
  }

  renderSchoolInfo(profile) {
    const schoolNameElem = <div>{profile.userData.schoolName}</div>;
    const schoolIdElem = (
      <span>
        <i>(ID #{profile.userData.schoolId})</i>
      </span>
    );

    return (
      <div className="top-margin-small">
        {schoolNameElem}
        {schoolIdElem}
      </div>
    );
  }

  renderLockElement(profile) {
    if (profile.userData.isLocked !== true) {
      return null;
    }

    return (
      <Alert className="top-margin bottom-margin large">
        <BsFillShieldLockFill className="largest"/> <Translated translationKey="user-account-locked" />
      </Alert>
    );
  }

  renderSubscriptionPeriods(userId) {
    return <SubscriptionPeriods userId={userId} />;
  }

  renderActivationDateElem(date) {
    if (date) {
      return (
        <div>
          <Translated translationKey="activation_date"/>: {DateUtil.dateTime(date)}
        </div>
      );
    } else {
      return (
        <div>
          <Translated translationKey="activation_date"/>:&nbsp;
          <Translated translationKey="not_yet_activated"/>
        </div>
      );
    }
  }

  renderOpenDaysElement() {
    const closingDays = [];
    if (this.props.isAdminView) {
      closingDays.push(
        <div key="customDays" className="p-2">
          <Form.Group>
            <Form.Label>
              <Translated translationKey="duration_days" />
            </Form.Label>
            <Form.Control
              value={this.state.customDayPeriod}
              onChange={(e) =>
                this.setState({ customDayPeriod: e.target.value })
              }
              type="number"
            />
          </Form.Group>
          <Button
            className="mt-2"
            onClick={() => this.saveClosingInDays(this.state.customDayPeriod)}
          >
            <Translated translationKey='set' />
          </Button>
        </div>
      );
    } else {
      [10, 35, 85].forEach((dayPeriod) =>
        closingDays.push(
          <Dropdown.Item
            id={dayPeriod + "d"}
            key={dayPeriod + "d"}
            onClick={() => this.saveClosingInDays(dayPeriod)}
          >
            {dayPeriod} <Translated translationKey="days" />
          </Dropdown.Item>
        )
      );
    }
   
    return (
      <DropdownButton id="closing-date" title={<Translated translationKey="set_closing_date"/>}>
        {closingDays}
      </DropdownButton>
    );
  }

  renderRemoveClosingDate() {
    return (
        <Button onClick={() => this.saveClosingInDays(null)}>
          <Translated
              translationKey="remove_closing_date"/>
        </Button>
    );
  }

  renderClosingElement(isManuallyClosed, isClosedByDate, closingDate) {
    if (isManuallyClosed) {
      return (
        <div className="light-blue-inner-box tight highlight-border vertical-middle">
          <div className="user-edit-login-credential-icon hide-xs">
            <IoLockClosedSharp className="edit-user-lock-icon"/>
          </div>
          <div>
            <strong><Translated translationKey="user_account_closed"/></strong>
            <br />
            <Button id="edit-user-save-button" onClick={() => {
              this.saveUserData({isClosed: false})
            }}>
              <Translated translationKey="open"/>
            </Button>
          </div>
        </div>
      );

    } else if (isClosedByDate) {
      return (
        <div className="light-blue-inner-box tight highlight-border vertical-middle">
          <div className="user-edit-login-credential-icon hide-xs">
            <IoLockClosedSharp className="edit-user-lock-icon"/>
          </div>
          <div>
            <strong>
              <Translated translationKey="user_account_closed_on" values={{'date': DateUtil.dateTime(closingDate)}}/>
            </strong>
            <br />
            {this.renderOpenDaysElement()}
            {this.renderRemoveClosingDate()}
          </div>
        </div>
      );

    } else if (closingDate) {

      return (
          <div>
            <div className="light-blue-inner-box tight vertical-middle">
              <div className="user-edit-login-credential-icon hide-xs">
                <IoCalendarClearSharp className="edit-user-calendar-icon"/>
              </div>
              <div>
                <Translated translationKey="user_account_closes_on" values={{'date': DateUtil.dateTime(closingDate)}}/>
                <br/>
                {this.renderOpenDaysElement()}
                {this.renderRemoveClosingDate()} <br/>
              </div>
            </div>
            <Button id="edit-user-close-button" onClick={() => {
              this.saveUserData({isClosed: true})
            }}>
              <IoLockClosedSharp/> <Translated translationKey="close"/>
            </Button>
          </div>
      );
    } else {
      return (
        <div className="top-margin">
          <div>
            <Translated translationKey="automatic_closing_info"/>
          </div>
          {this.renderOpenDaysElement()} <br/>
          <Button id="edit-user-close2" onClick={() => {
            this.saveUserData({isClosed: true})
          }}>
            <IoLockClosedSharp/> <Translated translationKey="close"/>
          </Button>
        </div>
      );
    }
  }

  renderUnactivatedOneTimeKeyCredentials() {
    const infoElem = (
      <div>
        <h4><Translated translationKey="unactivated_codes"/></h4>
        <Translated translationKey="no_one_time_key_handout_info"/>
      </div>
    );
    return (
      this.renderLoginCredentials('one-time', infoElem)
    );
  }

  renderNoOneTimeKeyCredentials() {
    return (
      this.renderLoginCredentials('one-time', <Translated translationKey="no_one_time_key_credentials"/>)
    );
  }

  renderOneTimeKeys(profile, usedKeyCount, totalKeyCount) {
    const keysElem = (
      <div>
        <Translated translationKey="one_time_keys_used_amount"/>: {usedKeyCount}/{totalKeyCount}
        <br/>
        {usedKeyCount > 0 ? this.renderOneTimeKeysResetButton(profile) : null}
        <Button
          disabled={this.isProfileClosed(profile)}
          onClick={() => this.showPrintableCodes()}
        >
          <Translated translationKey="view"/>
        </Button>
      </div>
    );
    return this.renderLoginCredentials('one-time', keysElem);
  }

  renderOneTimeKeysResetButton(profile) {
    return (
      <Button
        disabled={this.isProfileClosed(profile)}
        onClick={() => {
          this.saveUserData({resetKeys: true})
        }}
      >
        <span className="hide-xs"><Translated translationKey="reset_keys"/></span>
        <span className="hide-sm-and-gt"><IoLockOpenSharp/><Translated translationKey="open"/></span>
      </Button>
    );
  }

  showPrintableCodes() {
    if (!this.props.profile.userData.name) {
      Tracker.logModalView('print-codes-naming');
      this.setState({
        showNamingModal: true,
        name: ''
      })
    } else {
      Tracker.logModalView('print-codes');
      this.setState({
        showPrintableCodes: true,
        name: '',
      })
    }
  }

  renderNoEmailCredentials(profile) {
    const statusElem = (
      <div>
        <Translated translationKey="no_email_credentials"/><br/>
        <Button
          disabled={this.isProfileClosed(profile) || !this.isProfileActivated(profile)}
          onClick={() => this.setState({showAddEmail: true})}>
          <Translated translationKey="add"/>
        </Button>
        {this.renderAddEmailModal(profile)}
      </div>
    );
    return this.renderLoginCredentials('email', statusElem);
  }

  renderEmailCredentials(profile) {
    if(this.state.isEditingEmail) {
      return this.renderLoginCredentials(
        "email",
        <div className="edit-user-name">
          <div className="edit-username">
            <Form.Control
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.props.changeUserEmail(this.state.email, () =>
                    this.setState({ isEditingEmail: false })
                  );
                }
                if (e.key === "Escape") {
                  this.setState({ isEditingEmail: false });
                  this.props.resetAdminError("changeEmailError");
                }
              }}
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              type="email"
            />

            <RiCheckFill
              fontSize={32}
              className="touchable"
              onClick={() =>
                this.props.changeUserEmail(this.state.email, () =>
                  this.setState({
                    isEditingEmail: false,
                    email: this.props.profile.userData.email,
                  })
                )
              }
            />
            <RiCloseFill
              fontSize={32}
              className="touchable"
              onClick={() =>
                this.setState({
                  isEditingEmail: false,
                  email: this.props.profile.userData.email,
                })
              }
            />
          </div>
          {this.props.adminErrors.changeEmailError && (
            <Alert className="p-0 my-1">
              <Translated
                translationKey={this.props.adminErrors.changeEmailError}
              />
            </Alert>
          )}
          {this.props.isSaving ? <Spinner className="primary" /> : null}
        </div>
      );
    }
    const emailText = this.props.translate('can_login_email', {email: profile.userData.email});
    const hasEmail = !!this.props.profile.userData.email;
    const hasEmailPassword = this.props.profile.userData.hasEmailPassword;
    return this.renderLoginCredentials(
      "email",
      <>
        <span>
          {emailText}&nbsp;
          {this.props.isAdminView && (
            <RiEdit2Fill
              className="touchable"
              onClick={() => this.setState({ isEditingEmail: true })}
            />
          )}
        </span>
        <div className="mt-1 p-0">
          <Button
            className="mt-1"
            onClick={() => this.setState({ isModalPasswordRecovery: true })}
          >
            <Translated translationKey='send_reset_link' />
          </Button>
          {this.props.isAdminView && (
            <div>
              {hasEmail && (
                <>
                  <Button onClick={this.disconnectEmail}><Translated translationKey='disconnect' /></Button>
                  {this.props.adminErrors.disconnectEmailError && (
                    <Alert className="p-0 my-1">
                      <Translated
                        translationKey={
                          this.props.adminErrors.disconnectEmailError
                        }
                      />
                    </Alert>
                  )}
                </>
              )}
              <br />
              {hasEmailPassword ? (
                <>
                  <Button onClick={this.removeEmailPassword}>
                    <Translated translationKey='remove_password' />
                  </Button>
                  {this.props.adminErrors.removeEmailPasswordError && (
                    <Alert className="p-0 my-1">
                      <Translated
                        translationKey={
                          this.props.adminErrors.removeEmailPasswordError
                        }
                      />
                    </Alert>
                  )}
                </>
              ) : (
                <Alert className="p-1 m-0">
                  <Translated
                    translationKey={
                      "no_password"
                    }
                  />
                </Alert>
              )}
            </div>
          )}
        </div>
      </>
    );
  }

  renderNoFacebookCredentials() {
    return this.renderLoginCredentials(
      'facebook',
      <Translated translationKey="no_facebook_credentials"/>
    );
  }

  renderFacebookCredentials(profile) {
    const statusElem = (
      <div>
        <Translated translationKey="facebook_is_connected"/><br/>
        <strong>{profile.facebookUser.name}</strong>
      </div>
    );
    return this.renderLoginCredentials('facebook', statusElem);
  }

  renderLoginCredentials(iconElemId, statusElem) {
    let iconElem;
    switch (iconElemId) {
      case "email":
        iconElem = <IoMail className="largest"/>
        break;
      case "facebook":
        iconElem = <IoLogoFacebook  className="largest"/>
        break;
      case "one-time":
        iconElem = <IoReader  className="largest"/>
        break;
    }
    return (
      <div className="vertical-middle blue-inner-box tight">
        <div className="user-edit-login-credential-icon hide-xs">
          {iconElem}
        </div>
        <div>
          {statusElem}
        </div>
      </div>
    );
  }

  renderAddEmailModal(profile) {
    return (
      <Modal show={this.state.showAddEmail} onHide={() => this.setState({showAddEmail: false})}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translated translationKey={'connect_email_credentials'}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserAddEmail userId={profile.userData.id} userName={profile.userData.name}/>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    let profile = this.props.profile;
    let userData = profile.userData;

    const oneTimeKeys = profile.oneTimeKeys ? profile.oneTimeKeys : [];
    const oneTimeKeyCount = oneTimeKeys.length;
    const usedOneTimeKeyCount = oneTimeKeys.filter(key => !key.isOpen).length;
    const activationDate = userData.activationDate;

    const hasEmail = !!profile.userData.email;
    const hasFacebook = !!profile.facebookUser;
    const isUnActivatedOneTimeKeyAccount = !activationDate && (!hasEmail && !hasFacebook);
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);

    let credentialsElem;

    if (isTeacher) {
      credentialsElem = (
          <div>
            {hasFacebook ? this.renderFacebookCredentials(profile) : this.renderNoFacebookCredentials()}
            {hasEmail ? this.renderEmailCredentials(profile) : this.renderNoEmailCredentials(profile)}
          </div>
      );
    } else if (isUnActivatedOneTimeKeyAccount) {
      // Not yet taken into use and user account is created through one-time-key process.
      // Show note about this account. Should be given to a student only on paper.
      credentialsElem = this.renderUnactivatedOneTimeKeyCredentials();
    } else {
      // Render all credential information.
      credentialsElem = (
        <div>
          {hasFacebook ? this.renderFacebookCredentials(profile) : this.renderNoFacebookCredentials()}
          {hasEmail ? this.renderEmailCredentials(profile) : this.renderNoEmailCredentials(profile)}
          {oneTimeKeyCount > 0
            ? this.renderOneTimeKeys(profile, usedOneTimeKeyCount, oneTimeKeyCount)
            : this.renderNoOneTimeKeyCredentials()}
        </div>
      );
    }

    return (
      <div id="edit-user-container" className="edit-user-profile-wrapper">
        {this.renderImage(profile.facebookUser)}
        <div className="edit-user-profile-data">
          {this.renderNameElem(profile)}
          {this.renderActivationDateElem(activationDate)}
          {this.renderSchoolInfo(profile)}
          {this.renderLockElement(profile)}
          {this.renderClosingElement(userData.isClosed, userData.isClosedByDate, userData.closingDate)}
          {credentialsElem}
        </div>
        {this.renderPrintableCodes(profile)}
        {this.renderResetPasswordModal()}
        {this.renderNamingModal()}
      </div>
    );
  }
}

export default withTranslate(EditUser);
