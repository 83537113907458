import React, {Component} from 'react';
import Translated from '../Translated';
import Lightbox from 'react-image-lightbox';
import TrackerAB from '../../../utils/trackerAB';

const THUMB_T = require('../../../assets/webshop/t_thumbnail.png');
const THUMB_M = require('../../../assets/webshop/m_thumbnail.png');
const GEN_THUMB_MOBILE = require('../../../assets/webshop/thumb_b_full_test_mobile.jpg');

const GEN_LANDING = require('../../../assets/webshop/b_landing.jpg');
const GEN_TEST = require('../../../assets/webshop/b_full_test.jpg');
const GEN_QUESTIONS = require('../../../assets/webshop/b_training_images.jpg');
const GEN_QUESTIONS_BOOK = require('../../../assets/webshop/b_training_images_book.jpg');
const GEN_TEST_MOBILE = require('../../../assets/webshop/b_full_test_mobile.jpg');
const GEN_LITERAL = require('../../../assets/webshop/b_literal.jpg');
const GEN_LITERAL_2 = require('../../../assets/webshop/gen_literal_training.png');
const GEN_SIGNS = require('../../../assets/webshop/signs_training.jpg');
const GEN_SIGNS_MOBILE = require('../../../assets/webshop/traffic_signs_training.jpg');
const GEN_SIGNS_MOBILE_2 = require('../../../assets/webshop/traffic_signs_training_2.jpg');
const GEN_SIGNS_MOBILE_3 = require('../../../assets/webshop/signs_training_mobile_2.jpg');

const T_LANDING = require('../../../assets/webshop/t_landing.jpg');
const T_QUESTIONS = require('../../../assets/webshop/t_questions.jpg');
const T_QUESTIONS_2 = require('../../../assets/webshop/t_questions_2.jpg');

const A_LANDING = require('../../../assets/webshop/a_landing.png');
const A_TRAINING_IMAGE = require('../../../assets/webshop/a_training_image.png');

const AM_LANDING = require('../../../assets/webshop/am_landing.png');

const BE_LANDING = require('../../../assets/webshop/be_landing.png');
const BE_QUESTIONS_1 = require('../../../assets/webshop/be_literal_3.png');
const BE_QUESTIONS_2 = require('../../../assets/webshop/be_literal_4.png');

const M_LANDING = require('../../../assets/webshop/m_landing.jpg');
const M_QUESTIONS = require('../../../assets/webshop/m_training_image.jpg');
const M_QUESTIONS_BOOK = require('../../../assets/webshop/m_training_book.jpg');
const M_QUESTIONS_2 = require('../../../assets/webshop/m_training_literal.jpg');
const M_QUESTIONS_3 = require('../../../assets/webshop/m_training_literal_2.jpg');

const PROMO_IMAGES = {
  'generic':
    {
      'thumbnail': GEN_THUMB_MOBILE,
      'thumbnail_mobile': GEN_THUMB_MOBILE,
      'images': [GEN_LANDING, GEN_TEST, GEN_QUESTIONS, GEN_QUESTIONS_BOOK, GEN_LITERAL, GEN_SIGNS, GEN_SIGNS_MOBILE]
    },
  't_class':
    {
      'thumbnail': THUMB_T,
      'thumbnail_mobile': THUMB_T,
      'images': [T_LANDING, T_QUESTIONS, T_QUESTIONS_2, GEN_SIGNS_MOBILE, GEN_TEST, GEN_QUESTIONS]
    },
  'm_class':
    {
      'thumbnail': THUMB_M,
      'thumbnail_mobile': THUMB_M,
      'images': [M_LANDING, M_QUESTIONS, M_QUESTIONS_BOOK, M_QUESTIONS_2, M_QUESTIONS_3, GEN_SIGNS_MOBILE_2, GEN_SIGNS_MOBILE_3]
    },
  'be_class':
    {
      'thumbnail': THUMB_T,
      'thumbnail_mobile': THUMB_T,
      'images': [BE_LANDING, BE_QUESTIONS_2, BE_QUESTIONS_1, GEN_SIGNS_MOBILE_3, GEN_TEST, GEN_QUESTIONS]
    },
  'a_class':
    {
      'thumbnail': THUMB_T,
      'thumbnail_mobile': THUMB_T,
      'images': [A_LANDING, GEN_LITERAL_2, A_TRAINING_IMAGE, GEN_SIGNS_MOBILE_3]
    },
  'am_class':
    {
      'thumbnail': THUMB_T,
      'thumbnail_mobile': THUMB_T,
      'images': [AM_LANDING, GEN_LITERAL_2, GEN_SIGNS_MOBILE, GEN_TEST]
    }
};

class ImagesComponent extends Component {

  constructor(props, context) {
    super(props, context);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      photoIndex: 0,
      showModal: false
    }
  }

  renderThumbnail(setId) {
    const fileName = PROMO_IMAGES[setId].thumbnail;
    const fileNameMobile = PROMO_IMAGES[setId].thumbnail_mobile;
    return (
      <div>
        <img src={fileName} className="promo-thumbnail touchable hide-xs" alt="Sample images thumbnail"/>
        <img src={fileNameMobile} className="promo-thumbnail touchable hide-sm-and-gt" alt="Sample images thumbnail"/>
      </div>
    );
  }

  openModal() {
    TrackerAB.track('webshop-images-opened');
    this.setState({showModal: true});
  }

  closeModal() {
    TrackerAB.track('webshop-images-closed');
    this.setState({showModal: false});
  }

  renderLightbox(setId) {
    const {photoIndex, showModal} = this.state;
    if (!showModal) {
      return null;
    }

    const images = PROMO_IMAGES[setId].images;

    return (
      <div>
        <div className="hide-xs">
          <Lightbox
            enableZoom={false}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={this.closeModal}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        </div>
      </div>
    );
  }

  render() {
    const setId = this.props.config['setId'];
    return (
      <div>
        <div onClick={this.openModal} className="touchable">
          {this.renderThumbnail(setId)}
          <span onClick={this.openModal} className="yellow strong"><Translated translationKey="show_images" /></span>
        </div>
        {this.renderLightbox(setId)}
      </div>
    )
  }

}

export default ImagesComponent;

