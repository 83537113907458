import React from 'react';
const PrivacyPolicy = () => (
    <div className="page-container max-width-container">
      <h2>Tietosuojaseloste</h2>
      <h3>Rekisterinpitäjä</h3>
      Ajokaista Oy
      <br/>
      Katajapolku 14
      <br/>
      00780 Helsinki
      <br/>
      Y-tunnus: 2924778-7

      <h3>Rekisterin nimi</h3>
      Ajokaistan asiakasrekisteri

      <h3>Henkilötietojen käsittelyn tarkoitus ja käsittelyperusteet</h3>
      Rekisterin henkilötietoja käsitellään, jotta Ajokaista voi tarjota palvelun rekisteröidyn ja Ajokaistan välisen
      sopimuksen mukaisesti. Henkilötietoja käytetään erityisesti:
      <ul>
        <li>Palveluun kirjautumiseen ja käyttöön</li>
        <li>Palvelun omaan tiedottamiseen</li>
        <li>Järjestelmän testaukseen, ylläpitoon ja kehittämiseen</li>
        <li>Asiakaskyselyihin</li>
        <li>Laskutukseen</li>
      </ul>
      Joudumme ajoittain käyttämään tietoja myös väärinkäytösten estämiseen ja tutkimiseen. Esimerkiksi automaattisesti
      kerättyjen lokitietojen avulla voimme valvoa ja selvittää tietojärjestelmän käyttäjän toimia ja tietojen käytön
      luvallisuutta. Yritysasiakkaiden yhteystietoja voimme käsitellä myös markkinointitarkoituksia varten. Tällöin
      käsittely perustuu oikeutettuun etuumme.

      Laskutustietojen käsittely perustuu myös lakisääteisen velvoitteen noudattamiseen.

      <h3>Rekisterin tietosisältö</h3>
      Yhteystiedot, kuten nimi, sähköpostiosoite, puhelinnumero
      Palvelun käyttäjän autokoulun nimi tai yritysasiakkaan työnantajan nimi
      Palvelun käyttöä kuvaavat tiedot, kuten harjoittelun suoritukset
      Tilatut tuotteet/palvelut
      Facebook kirjautumista koskevat tiedot
      Yhteydenotot ja palautteet
      Laskutustiedot
      Yllä mainitut henkilötiedot kerätään lähtökohtaisesti rekisteröidyltä itseltään joko palveluun kirjautumisen tai
      käytön yhteydessä taikka yhteydenottolomakkeen kautta. Jos palvelu on osa autokoulun tai liiton tarjoamaa
      palvelua, henkilötiedot voidaan saada myös ko. taholta.

      <h3>Tietojen säilytysaika</h3>
      Säilytämme palvelun käyttäjän henkilötietoja kolme vuotta viimeisimmästä kirjautumisesta, ellei rekisteröity pyydä
      poistamaan tietoja tätä aiemmin. Yritysasiakkaiden yhteyshenkilöiden tietoja säilytämme niin kauan kuin yhteistyö
      kestää ja sen päätyttyä kohtuullisen ajan palvelun markkinoimiseksi. Tositteita ja liiketapahtumia koskevaa
      kirjeenvaihtoa säilytämme kuusi vuotta sen kalenterivuoden lopusta, jonka aikana tilikausi päättyy.

      <h3>Tietojen luovutus</h3>
      Rekisterin tietoja ei pääsääntöisesti luovuteta kolmansille osapuolille. Mikäli kuitenkin myymme, fuusioimme tai
      muutoin järjestelemme liiketoimintaamme, rekisteröidyn henkilötietoja voidaan luovuttaa ostajille ja näiden
      neuvonantajille. Voimme ajoittain joutua luovuttamaan rekisteröidyn henkilötietoja myös viranomaisille taikka
      perintäyhtiöille.

      <h3>Tietojen siirto EU:n tai ETA:n ulkopuolelle</h3>
      Tietoja ei pääsääntöisesti siirretä EU:n tai ETA:n ulkopuolelle. Mikäli tietoja poikkeuksellisesti siirretään EU-
      tai ETA-alueen ulkopuolelle, takaamme sopimusjärjestelyin, että tietoja käsitellään kulloinkin voimassaolevan
      lainsäädännön ja tämän tietosuojaselosteen mukaisesti.

      <h3>Rekisterin suojauksen periaatteet</h3>
      Rekisteriä suojataan asianmukaisin teknisin keinoin. Rekisterin käyttöoikeus edellyttää henkilökohtaista
      käyttäjätunnusta ja salasanaa, jotka myönnetään vain rekisterinpitäjän henkilökuntaan kuuluvalle, jonka asemaan ja
      tehtäviin mainittu käyttöoikeus liittyy.

      <h3>Rekisteröidyn oikeudet</h3>
      Rekisteröity voi vaikuttaa käsittelyyn seuraavin tavoin:

      tarkistaa, mitä häntä koskevia tietoja Ajokaistan asiakasrekisteriin on tallennettu
      vaatia virheellisen tiedon oikaisua tai puutteellisen tiedon täydentämistä
      pyytää tietojaan poistetuksi
      saada siirretyksi toimittamansa henkilötiedot, joita käsitellään automaattisesti suostumuksen tai sopimuksen
      perusteella
      vastustaa henkilökohtaiseen tilanteeseensa liittyvällä perusteella käsittelyä, joka perustuu oikeutettuun etuun
      kieltäytyä markkinoinnista milloin tahansa
      tehdä valituksen viranomaiselle, jos henkilötietoja on käsitelty voimassaolevan tietosuojalainsäädännön
      vastaisesti.

      Oikeuksien käyttämiseksi pyydämme ottamaan yhteyttä yllä kohdassa 2 mainittuun osoitteeseen. Vastaamme
      yhteydenottoon mahdollisimman pian ja viimeistään kuukauden kuluessa.

      <h3>Evästeet</h3>

      Käyttäjän päätelaitteelle voidaan tallentaa evästeitä. Evästeet tallentavat tietoa palvelun käytöstä ja
      käyttötavoista, esimerkiksi miltä sivulta käyttäjä on siirtynyt sivustolle, milloin ja mitä sivuston osaa käyttäjä
      on selannut, mitä selainta käyttäjä käyttää, mikä on käyttäjän näytön tarkkuus, laitteen käyttöjärjestelmä ja sen
      versio sekä IP-osoite. Evästeisiin saatetaan tallentaa myös joitakin käyttöä helpottavia tietoja, kuten valittu
      ajokorttiluokka.

      Käytämme seuraavan tyyppisiä evästeitä:
      <ul>

        <li>
          Välttämättömät evästeet: Nämä evästeet ovat välttämättömiä palveluiden ja niiden toimintojen käyttämiseksi,
          esimerkiksi sisäänkirjautumiseksi tai ostoskorin valintojen muistamiseksi.
        </li>

        <li>
          Analytiikkaevästeet: Saamamme tiedot kertovat meille, miten sivustoa käytetään. Käytämme palvelussamme mm.
          Google
          Analyticsiä mittaamaan sivustomme käyttöä. Googlen työkalujen käyttämiin evästeisiin tallennettavat tiedot
          (esimerkiksi IP-osoitteet) lähetetään edelleen tallennettaviksi Googlen palvelimiin eri puolilla maailmaa.
          Tästä
          johtuen kyseisiä tietoja voidaan käsitellä palvelimilla, jotka sijaitsevat muualla kuin käyttäjän asuinmaassa.
          Saamiensa tietojen avulla Google arvioi käyttäjän tapaa selata sivujen sisältöä ja laatii yhteenvetoraportteja
          sivuston käytöstä. Lisäksi Google laatii selvityksiä sivustojen yhteydessä tarjottavista palveluista ja
          tuottaa
          internetin käyttöä koskevia tilastoja. Google voi myös mukauttaa käyttäjille näkyvien mainosten sisältöä
          aiemman
          selaushistorian perusteella. Google voi myös välittää tietoja kolmansille osapuolille, jos lainsäädäntö sitä
          edellyttää, tai tapauksissa, joissa kolmas osapuoli käsittelee tietoa Googlen toimeksiannosta. Google ei
          yhdistä
          käyttäjän IP-osoitetta muuhun Googlen tallentamaan tietoon.
        </li>

        <li>
          Mainosevästeet: Nämä evästeet tallentavat tietoa käyttäjänä vierailusta, sivuista, joita käyttäjä on katsellut
          sekä linkeistä, joita käyttäjä on avannut. Niiden avulla voidaan mm. kohdentaa palveluun liittyvää mainontaa
          ulkopuolisilla sivustoilla.
        </li>

        <li>
          Sosiaalisen median evästeet: Palvelussa voidaan hyödyntää myös sosiaalisen median palveluiden, esimerkiksi
          Facebookin, evästeitä esimerkiksi jakopainikkeiden käytössä. Näillä evästeillä voidaan myös kohdentaa
          mainontaa.
          Evästettä käytetään myös, jos käyttäjä kirjautuu sivustolle Facebookin kautta.
        </li>
      </ul>

      Rekisteröity voi vaikuttaa evästeisiin seuraavin tavoin:

      <ul>
        <li>
          Uuden evästeprofiilin luominen: Tyhjentämällä evästeet selaimelta säännöllisin väliajoin käyttäjä vaihtaa
          yksilöivää tunnistetta, jolloin aiempaan tunnisteeseen liittyvä profiili nollautuu.
        </li>

        <li>
          Evästeiden estäminen: Käyttäjä voi estää evästeet sekä tyhjentää selaimen välimuistin selaimen asetuksista.
          Evästeiden käytön estäminen saattaa vaikuttaa palveluidemme toiminnallisuuteen.
        </li>

        <li>
          Google Analyticsin käytön estäminen: Käyttäjä voi estää sivuston tietojen käyttämisen Google Analyticsissa
          asentamalla Google Analyticsin käytön estävän selaimen lisäosan <a href="https://tools.google.com/dlpage/gaoptout/"  target="_blank" rel="noopener">täällä</a>. Tämä lisäosa estää verkkosivustoilla
          käynnissä olevaa Google Analyticsin JavaScriptia (ga.js, analytics.js ja dc.js) jakamasta sivuston
          käyntitietoja
          Google Analyticsille.
        </li>

        <li>
          Kohdennetun mainonnan estäminen: Käyttäjä voi kieltää mainonnan kohdentamisen verkkokäyttäytymiseen perustuen <a href="http://www.youronlinechoices.com/fi/omat-mainokset" target="_blank" rel="noopener">täällä</a>.
        </li>
      </ul>
    </div>
  );
export default PrivacyPolicy

