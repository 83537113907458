import * as Sentry from '@sentry/browser';
import jwtDecode from 'jwt-decode';

const SHORT_BURST_COUNT = 3;
const SHORT_BURST_SECS = 10;
const LONG_BURST_COUNT = 10;
const LONG_BURST_SECS = 600;
const messageTimestamps = {};

class Logger {

  /**
   * Initialize logger
   */
  static initialize() {

    if (SENTRY_PUBLIC_KEY) {
      const config = {
        dsn: SENTRY_PUBLIC_KEY,
        release: BUILD_VERSION,
        whitelistUrls: [
          /ajokaista\.com/,
          /ajokaista\.net/,
          /ajokaista\.fi/,
          /opetuslupa\.com/,
          /mopokortti\.com/,
          /mopokortti\.net/,
          /mopokortti\.org/,
          /mopokortti\.fi/,
          /teoriakoe\.com/,
          /ajokortti\.org/,
          /drivingschool\.fi/,
          /traktorikortti\.com/,

          /www\.ajokaista\.com/,
          /www\.ajokaista\.net/,
          /www\.ajokaista\.fi/,
          /www\.opetuslupa\.com/,
          /www\.mopokortti\.com/,
          /www\.mopokortti\.net/,
          /www\.mopokortti\.org/,
          /www\.mopokortti\.fi/,
          /www\.teoriakoe\.com/,
          /www\.ajokortti\.org/,
          /www\.drivingschool\.fi/,
          /www\.traktorikortti\.com/
        ],
        ignoreErrors: [
          'attachEvent',
          'requestAnimationFrame',
          'cancelAnimationFrame',
          'ResizeObserver loop limit exceeded',
          // Error generated by a bug in auto-fill library from browser
          // https://github.com/getsentry/sentry/issues/5267
          /Blocked a frame with origin/
        ]

      };
      Sentry.init(config);
    } else {
      console.log('Sentry not initialized!');
    }

  }

  /**
   * Set global user data (free form data object)*
   * @param userData the object, set undefined when user should be cleared (logout)
   */
  static setUserData(userData) {
    if (!userData) {
      Sentry.setUser(null);
    } else if (typeof userData === 'object' && userData.token) {
      let decoded = jwtDecode(userData.token);
      let formatted = {id: decoded.userId};
      Sentry.setUser(formatted);
    } else {
      Sentry.setUser({id: userData.toString()});
    }
  }

  /**
   * Store info type log message
   */
  static info(message, data) {
    console.info('INFO: ' + message);
    Logger._captureMessage(message, 'info', data);
  }

  /**
   * Store warning type log message (something seems to be wrong, but user might not notice anything out of place)
   */
  static warning(message, data) {
    console.warn('WARN: ' + message);
    Logger._captureMessage(message, 'warning', data);
  }

  /**
   * Store error type log message (something is wrong and user experience is affected)
   */
  static error(errorObj) {
    console.error(errorObj);
    Sentry.captureException(errorObj);
  }

  /**
   * Capture Message
   * @param message
   * @param level
   * @param data
   * @private
   */
  static _captureMessage(message, level, data) {
    if (Logger._isCaptureAllowed(message)) {
      Sentry.withScope(scope => {
        scope.setExtra('data', data);
        Sentry.captureMessage(message, level);
      });
    }
  }

  static _isCaptureAllowed(message) {
    let timeStamps = messageTimestamps[message];
    if (!timeStamps) {
      timeStamps = [];
      messageTimestamps[message] = timeStamps;
    }
    const now = Date.now();
    timeStamps.push(now);

    let start;
    if (timeStamps.length >= SHORT_BURST_COUNT) {
      start = timeStamps[timeStamps.length - SHORT_BURST_COUNT];
      if (now - start < SHORT_BURST_SECS * 1000) {
        // Too many events in a short time
        return false
      }
    }
    if (timeStamps.length >= LONG_BURST_COUNT) {
      start = timeStamps[timeStamps.length - LONG_BURST_COUNT];
      if (now - start < LONG_BURST_SECS * 1000) {
        // Too many events in a long time
        return false
      }
    }
    return true;
  }

}

export default Logger;