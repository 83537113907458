import {Map, fromJS} from 'immutable';
import jwtDecode from 'jwt-decode';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_COMPLETE,
  FB_CONNECT_REQUEST,
  FB_CONNECT_SUCCESS,
  FB_CONNECT_ERROR,
  FB_CONNECT_RESET,
  FB_SET_ACCESS_TOKEN,
  REQUIRE_LICENSE_FOR_FB_LOGIN,
  RESET_LOGIN_ERROR,
  PROFILE_ID_REQUIRED
} from '../actions/loginActions'

import {
  TOKEN_UPDATED,
  RESET_AUTHENTICATION,
  REQUIRE_TOS,
  ACCEPT_TOS
} from '../actions/authActions'

function setPersistedStringToken(tokenStr) {
  try {
    if (!tokenStr) {
      return localStorage.removeItem('token');
    }
    localStorage.setItem('token', tokenStr);
  } catch (error) {
    // Most likely private browsing
    console.warn('Error setting persisted token (Please avoid private browsing): '+error);
  }
}

function getPersistedDecodedToken() {
  const tokenStr = localStorage.getItem('token');
  return decodeToken(tokenStr);
}

function decodeToken(tokenStr) {
  let decoded;
  try {
    decoded = jwtDecode(tokenStr);
    // Append token string to payload object
    decoded['encoded'] = tokenStr;
  } catch (e) {
    decoded = null;
  }
  return decoded;
}

function getInitialState() {
  const decoded = getPersistedDecodedToken();
  return fromJS({
    isFetching: false,
    token: decoded,
    isFbConnecting: false,
    autoConnectFb: false,
    error: null,
    errorData: null,
    requiredTos: null,
    acceptedTos: null
  });
}

function login(state, action) {
  if (!state) {
    state = getInitialState();
  }
  switch (action.type) {

    case TOKEN_UPDATED:
      setPersistedStringToken(action.token);
      return state.merge({
        'token': Map(decodeToken(action.token))
      });

    case RESET_LOGIN_ERROR:
      return state.merge({
        'error': null,
        'errorData': null,
        'loggedOutMessage': null
      });

    case LOGIN_REQUEST:
      return state.merge({
        'error': null,
        'errorData': null,
        'isFetching': true,
        'token': null,
        'loggedOutMessage': null
      });

    case LOGIN_SUCCESS:
      state = state.merge({
        'isFetching': false,
        'error': null,
        'errorData': null,
        'profiles': null
      });
      return state;

    case PROFILE_ID_REQUIRED:
      state = state.merge({
        'isFetching': false,
        'token': null,
        'profiles': fromJS(action.profiles)
      });
      return state;

    case LOGIN_FAILED:
      return state.merge({
        'isFetching': false,
        'token': null,
        'error': action.error,
        'errorData': fromJS(action.data),
        'fb': action.data ? fromJS(action.data.fb) : undefined,
        'profiles': null
      });

    case LOGOUT_COMPLETE:
      setPersistedStringToken(null);
      return state.merge({
        'isFetching': false,
        'token': null,
        'error': null,
        'errorData': null,
        'fbAccessToken': null,
        'loggedOutMessage': action.loggedOutMessage,
        'profiles': null
      });

    case FB_CONNECT_REQUEST:
      return state.merge({
        'isFbConnecting': true
      });

    case FB_SET_ACCESS_TOKEN:
      return state.merge({
        'fbAccessToken': action.token
      });

    case FB_CONNECT_SUCCESS:
      return state.merge({
        'isFbConnecting': false,
        'autoConnectFb': false,
        'fbConnectError': null,
        'fbConnectErrorInfo': null
      });

    case FB_CONNECT_ERROR:
      return state.merge({
        'isFbConnecting': false,
        'autoConnectFb': false,
        'fbConnectError': action.error,
        'fbConnectErrorInfo': fromJS(action.info)
      });

    case FB_CONNECT_RESET:
      return state.merge({
        'isFbConnecting': false,
        'autoConnectFb': false,
        'fbConnectError': null,
        'fbConnectErrorInfo': null,
        'fbAccessToken': null
      });

    case REQUIRE_LICENSE_FOR_FB_LOGIN:
      return state.merge({
        'fb': fromJS(action.payload),
        'isFetching': false,
        'autoConnectFb': true
      });

    case RESET_AUTHENTICATION:
      setPersistedStringToken(null);
      return state.merge({
        'token': null,
        'fbAccessToken': null,
        'error': action.error
      });

    case REQUIRE_TOS:
      return state.merge({
        'requiredTos': action.version
      });

    case ACCEPT_TOS:
      return state.merge({
        'acceptedTos': action.version
      });

    default:
      return state;
  }
}

export default login;
