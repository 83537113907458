import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import MeIntegrationPage from './MeIntegrationPage';
import MeTeacherPage from './MeTeacherPage';
import MeStudentPage from './MeStudentPage';
import {Roles, fillsRole} from '../../data/roles';
import {useLocation, useNavigate} from "react-router-dom";

export const MePage = props => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = location.state?.redirect;
    if(redirect) {
      navigate(redirect);
      return null;
    }
  })

  if (!props.token) {
    return null;
  }

  const role = props.token.get('role');
  const integrationPlatform = props.token.get('integrationPlatform');

  if (integrationPlatform) {
    return <MeIntegrationPage allowFacebook={false} integrationPlatform={integrationPlatform}/>;
  } else if (fillsRole(role, Roles.TEACHER)) {
    return <MeTeacherPage allowFacebook={false}/>;
  } else {
    return <MeStudentPage allowFacebook={true}/>;
  }

}

let mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(MePage);
