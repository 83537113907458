import React, {Component} from 'react';
import Spinner from './../feature/Spinner';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {IoAlertOutline} from 'react-icons/io5';
import withTranslate from '../feature/withTranslate';
import Translated from './../feature/Translated';
import DateFormat from '../../utils/date';
import api from '../../api';
import withRouter from "../feature/withRouter";

const READER_URL = 'https://get.adobe.com/reader/';

class OrdersPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isFetching: false,
      latestOrders: null,
      canOrderMore: true,
      deliveryMethod: null,
      minDaysSinceLast: 0,
      submitted: false,
      school: null,
      error: null
    };
    this.translate = this.props.translate;
  }

  getSchoolId() {
    if (this.props.match && this.props.match.params.schoolId) {
      return this.props.match.params.schoolId;
    } else {
      return this.props.token.get('schoolId')
    }
  }

  componentDidMount() {
    if (!this.props.token) {
      return;
    }
    this.setState({
      isFetching: true
    });
    api.getLatestOneTimeKeyOrders(this.getSchoolId())
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            isFetching: false,
            latestOrders: null,
            canOrderMore: false,
          });
        }
        this.setState({
          error: null,
          isFetching: false,
          latestOrders: response.orders,
          canOrderMore: response.canOrderMore,
          minDaysSinceLast: response.minDaysSinceLast,
          school: response.school
        });
      });
  }

  postOrder() {
    if (this.props.token) {
      this.setState({
        error: null,
        isFetching: true,
        submitted: true
      });
      api.doOneTimeKeyOrder(this.getSchoolId(), this.state.deliveryMethod)
        .then(response => {
          if (response.error) {
            return this.setState({
              error: response.error,
              isFetching: false,
              latestOrders: null,
              canOrderMore: false,
            });
          }

          this.setState({
            error: null,
            isFetching: false,
            latestOrders: response.orders,
            canOrderMore: response.canOrderMore,
            minDaysSinceLast: response.minDaysSinceLast,
            school: response.school
          });
        });
    }
  }

  renderLoadingForm() {
    return (<div><Spinner className="primary" /></div>);
  }

  renderErrorForm() {
    return (<div><Alert><Translated translationKey={this.state.error}/></Alert></div>);
  }

  renderCannotOrderMoreForm() {
    const lastOrder = this.state.latestOrders[0];

    return (
      <Alert className="extra-side-margin">
        <h4><Translated translationKey="last_order"/></h4>
        <div>
          <Translated translationKey="order_quarantine" values={{'days': this.state.minDaysSinceLast}}/>
        </div>
        <div>
          <div><Translated translationKey="date"/>: {DateFormat.dateTime(lastOrder.date)}</div>
          {lastOrder.deliveryMethod === 'email'
            ? (<div><Translated translationKey="previous_delivery" values={{'address': lastOrder.deliveryAddress}}/></div>)
            : (<div><Translated translationKey="delivery_address"/>: {lastOrder.deliveryAddress}</div>)
          }
        </div>
      </Alert>
    );
  }

  renderSubmittedForm() {
    const lastOrder = this.state.latestOrders[0];

    return (
      <Alert>
        <h4><Translated translationKey="order_sent"/></h4>
        <div><Translated translationKey="delivery_address"/>: {lastOrder.deliveryAddress}</div>
      </Alert>
    );
  }

  renderOpenForm() {
    const emailAddress = this.state.school ? this.state.school.email : '';
    const postAddress = this.state.school ? this.state.school.streetAddress: '';

    return (
      <div>
        <Form.Check
            type='radio'
            name="deliveryMethod"
            value='email'
            label={this.translate('by_email') + ': ' + emailAddress}
            onChange={(e) => {
          if (e.target.checked) {
            this.setState({deliveryMethod: 'email'});
          }
        }} >
          {/*<Translated translationKey="by_email"/>: {emailAddress}*/}
        </Form.Check>
        <Form.Check
            type='radio'
            name="deliveryMethod"
            value='post'
            label={this.translate('by_post') + ': ' + postAddress}
            onChange={(e) => {
          if (e.target.checked) {
            this.setState({deliveryMethod: 'post'});
          }
        }} >
          {/*<Translated translationKey="by_post"/>: {postAddress}*/}
        </Form.Check>
        <div className="field-spacer"></div>
        <Button disabled={this.state.deliveryMethod === null} onClick={() => this.postOrder()}><Translated translationKey="send"/>
        </Button>
      </div>
    );
  }

  render() {
    let form;
    if (this.state.isFetching) {
      form = this.renderLoadingForm();

    } else if (this.state.submitted) {
      form = this.renderSubmittedForm();

    } else if (this.state.error) {
      form = this.renderErrorForm();

    } else if (!this.state.canOrderMore) {
      form = this.renderCannotOrderMoreForm();

    } else {
      form = this.renderOpenForm();
    }

    return (
      <div id="orders-page" className="page-container max-width-container fade-in">
        <h3>
          <Translated translationKey="order_passes"/>
        </h3>
        <p>
          <Translated translationKey="order_passes_info"/>
          <br/>
          <span id="link-to-adobe-reader">
            <a href={READER_URL} target="_blank" rel="noopener"><Translated translationKey="link_text_to_reader"/></a>
          </span>
        </p>
        <Alert>
          <IoAlertOutline/>&nbsp;
          <Translated translationKey="one_time_key_usage"/>
        </Alert>
        {form}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(OrdersPage)));
