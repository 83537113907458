import React, {Component, Fragment} from 'react';
import {IoReader, IoMail} from 'react-icons/io5';
import LoginForm from '../feature/LoginForm';
import LoginFormEmail from '../feature/LoginFormEmail';
import LoginStatusDisplay from '../feature/LoginStatusDisplay';
import {connect} from 'react-redux';
import Translated from '../feature/Translated';
import {loginWithFacebook, resetFacebookConnection} from '../../actions/loginActions'
import withTranslate from '../feature/withTranslate';
import News from '../feature/News';
import FacebookLoginButton from '../feature/FacebookLoginButton';
import Logger from '../../utils/logger';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import {resetLoginError} from '../../actions/loginActions'
import DataPrivacyNote from '../feature/DataPrivacyNote';
import withRouter from "../feature/withRouter";


export class LoginPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.facebookAuthResponse = this.facebookAuthResponse.bind(this);
    this.loginWithFacebook = this.loginWithFacebook.bind(this);
    this.state = {
      loginType: null
    }
  }

  componentDidMount() {
    const params = queryString.parse(window.location.hash);
    let fbToken = params['access_token'];
    if (fbToken) {
      // Username is optional, it will be pre-filled to login form if it is available
      this.loginWithFacebook('', fbToken);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token && !prevProps.token) {
      // Login happened
      this.props.navigate('/', {state: this.props.location.state, replace: true});
    }
  }

  loginWithFacebook(userName, token) {
    if (!this.props.isLoggingIn) {
      this.props.dispatch(loginWithFacebook(userName, token, this.props.navigate));
    }
  };

  facebookAuthResponse(response) {
    this.setState({loginType: null});
    if (!response) {
      Logger.info('Facebook auth response was empty');
      this.props.dispatch(resetFacebookConnection());
      return;

    } else if (!response.accessToken) {
      this.props.dispatch(resetFacebookConnection());
      return;
    }
    this.loginWithFacebook(response.name, response.accessToken);
  }

  setLoginType(type) {
    this.props.dispatch(resetLoginError());
    this.setState({loginType: type})
  }

  renderLoginEmailButton() {
    return (
      <Button aria-label="Login with email" role="button" onClick={() => this.setLoginType('email')} size="lg"
              className="full-width fade-in top-margin">
        <IoMail />
        <Translated translationKey="login_with_email"/>
      </Button>
    );
  }

  renderLoginOneTimeKeyButton() {
    return (
      <Button aria-label="Login with one-time-keys" role="button" onClick={() => this.setLoginType('one-time-key')}
              size="lg" className="full-width fade-in top-margin">
        <IoReader />
        <Translated translationKey="login_with_one_time_key"/>
      </Button>
    );
  }

  renderTeacherButton() {
    return (
      <div>
        <Button aria-label="Login with one-time-keys" role="button" size="lg"
                onClick={() => this.props.navigate('/login-teacher')} className="fade-in top-margin">
          <Translated translationKey="login"/>
        </Button>
      </div>
    )
  }

  renderLoginFacebookButton() {
    return (

      <FacebookLoginButton callback={this.facebookAuthResponse} label={this.props.translate('login_with_facebook')}
                           className="full-width fade-in top-margin"/>

    );
  }

  render() {
    let form;
    let buttons;
    let emailLoginIconHeader = <IoMail />;
    let oneTimeKeyLoginIconHeader = <IoReader />;

    if (this.state.loginType === 'email') {
      const formHeader = (
        <div className="gray">
          {emailLoginIconHeader}&nbsp;
          <Translated translationKey="login_email" className="left-margin"/>
        </div>
      );
      form = <LoginFormEmail key="email-form" header={formHeader}/>;
      buttons = (
        <Fragment>
          {this.renderLoginOneTimeKeyButton()}
          {this.renderLoginFacebookButton()}
        </Fragment>
      )
    } else if (this.state.loginType === 'one-time-key') {
      const formHeader = (
        <div className="gray">
          {oneTimeKeyLoginIconHeader}&nbsp;
          <Translated translationKey="login_one_time_key" className="left-margin"/>
        </div>
      );
      form = <LoginForm name="" schoolId="" loginToken="" header={formHeader}/>;
      buttons = (
        <Fragment>
          {this.renderLoginEmailButton()}
          {this.renderLoginFacebookButton()}
        </Fragment>
      )
    } else {
      form = null;
      buttons = (
        <Fragment>
          <LoginStatusDisplay/>
          {this.renderLoginOneTimeKeyButton()}
          {this.renderLoginEmailButton()}
          {this.renderLoginFacebookButton()}
        </Fragment>
      );
    }

    return (
      <div id="login-page" className="page-container page-gutter max-width-container">
        <div className="front-center-wrapper medium-top-margin">
          <News simple className="fade-in long-delay"/>

          <h1><Translated translationKey="login"/></h1>

          <div>
            <div className="login-form-container fade-in">
              {form}
            </div>
          </div>

          <div>
            <div className="login-buttons-container top-margin">
              {buttons}
              <div className="semi-dark-inner-box tight large-top-margin">
                <Translated translationKey="login_school_teacher"/>
                {this.renderTeacherButton()}
              </div>
              <DataPrivacyNote className="semi-dark-inner-box centered"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    token: state.login.get('token'),
    isLoggingIn: state.login.get('isFetching'),
    loginError: state.login.get('error'),
    locale: state.Intl.locale,
  }
}

export default withRouter(connect(mapStateToProps)(withTranslate(LoginPage)));
